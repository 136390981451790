import axios from 'axios';
import moment from 'moment';

export const serverUrl = `${process.env.REACT_APP_SERVER_URL || ''}`;

axios.defaults.baseURL = serverUrl;
axios.defaults.headers.post['Content-Type'] ='application/json';

function setCompanyCodeHeader() {
    let companyCode = localStorage.getItem("companyCode");
    console.log(companyCode);
    if(companyCode) {
        axios.defaults.headers.common['companyCode'] = companyCode;
    }
}

const ServerGateway = {
    validateLogin: async function(emailId, password) {
        try {
            const payload = { emailId: emailId, password: password };
            let response = await axios.post('/user/validate', payload);
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::validateLogin ->"+ error);
            throw error;
        }
        return false;
    },
    getEmployees: async function(name, groupId, pageNumber = 1, pageSize = 100) {
        try {
            setCompanyCodeHeader();
            const params = { name, groupId, pageNumber, pageSize, fields: 'Id,Name,EmployeeNumber,GroupName,DesignationName'  };
            let response = await axios.get('/master/employee', { params });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getEmployees ->"+ error);
            throw error;
        }
        return undefined;
    },    
    getEmployee: async function(id) {
        try {
            setCompanyCodeHeader();
            let response = await axios.get('/master/employee/'+ id);
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getEmployees ->"+ error);
            throw error;
        }
        return undefined;
    },
    getPayslips: async function(year, month) {
        try {
            setCompanyCodeHeader();
            const params = { financialYear: year, month, payslipType: 'Salary', groupId: 0, processedOnly: true  };
            let response = await axios.get('/employee/payslip', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getPayslip ->"+ error);
            throw error;
        }
        return undefined;
    },
    getAttendance: async function(date, groupId) {
        try {
            setCompanyCodeHeader();
            let endDate = moment(date).add(1, 'days').format("YYYY-MM-DD");
            const params = { durationId: "-1", from: date, to: endDate, groupId  };
            let response = await axios.get('attendance/shiftwise/daily', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getTransactions ->"+ error);
            throw error;
        }
        return undefined;
    },
    getLeaveTypes: async function() {
        try {
            setCompanyCodeHeader();
            let response = await axios.get('/attendance/leaveType');
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLeaveTypes ->"+ error);
            throw error;
        }
        return undefined;
    },
    getEmployeeGroups: async function() {
        try {
            setCompanyCodeHeader();
            let response = await axios.get('/master/employee/group');
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLeaveTypes ->"+ error);
            throw error;
        }
        return undefined;
    },
    getLeaveBalance: async function(employeeId) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId  };
            let response = await axios.get('/attendance/leave/balance', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLeaveTypes ->"+ error);
            throw error;
        }
        return undefined;
    },
    getLeaves: async function(employeeId, durationId, from, to) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId, durationId, from, to  };
            let response = await axios.get('/attendance/leave', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLeaveTypes ->"+ error);
            throw error;
        }
        return undefined;
    },
    getLocations: async function() {
        try {
            setCompanyCodeHeader();
            let response = await axios.get('/company/location');
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getLocations ->"+ error);
            throw error;
        }
        return undefined;
    },
    getGatepass: async function(employeeId, durationId) {
        try {
            setCompanyCodeHeader();
            const params = { employeeId, durationId  };
            let response = await axios.get('/attendance/gatepass', { params });
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getGatepass ->"+ error);
            throw error;
        }
        return undefined;
    },
    getCompanySettings: async function() {
        try {
            setCompanyCodeHeader();
            let response = await axios.get('/master/settings');
            if (response) {            
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getGatepass ->"+ error);
            throw error;
        }
        return undefined;
    }
}

export default ServerGateway;