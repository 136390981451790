import { Types } from './actionTypes';

const initialState = {
  profile: {
    userName: '',
    name: '',
    emailId: '',
    salesPersonId: 0
  },
  company: null,
  isUserLoggedIn: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
            ...state,
            profile: action.payload.user,
            company: null,
            isUserLoggedIn: true
      }
    case Types.SELECT_COMPANY:
        localStorage.setItem("companyCode", action.payload.company.shortName);
        return {
              ...state,
              company: action.payload.company
        }
    case Types.CHANGE_COMPANY:
        localStorage.removeItem("companyCode");
        return {
              ...state,
              company: null
        }
    case Types.LOGOUT:
      localStorage.clear();
      return {
            ...state,
            profile: initialState,
            company: null,
            isUserLoggedIn: false
        }
    default:
      return state;
  }
}

export default reducer;