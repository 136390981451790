import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { NavLink } from 'react-router-dom';

export const Profile = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const params = useParams()
    const [employee, setEmployee] = useState<Employee>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        loadEmployee();
    }, [params]);

    const loadEmployee = () => {
        setIsLoading(true);
        ServerGateway
            .getEmployee(params.id)
            .then((data) => {
                setEmployee(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <div className="page-profile">
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <>
                    <div className='container'>
                        <div className='row'>
                            <h2>{employee?.name}</h2>
                        </div>
                    </div>
                    <div className="accordion" id="employeeAccordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="basicHeading">
                                <div className="accordion-button bg-dark" data-bs-toggle="collapse" data-bs-target="#basicDetails" aria-expanded="true" aria-controls="basicDetails">
                                    Basic Details
                                </div>
                            </h2>
                            <div className="accordion-collapse collapse show" aria-labelledby="basicHeading" id="basicDetails" data-bs-parent="#employeeAccordion">
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Name
                                            <div className="fw-bold">{employee?.name}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Employee Number
                                            <div className="fw-bold">{employee?.employeeNumber}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Designation
                                            <div className="fw-bold">{employee?.designationName}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Group\ Department
                                            <div className="fw-bold">{employee?.groupName}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="contactHeader">
                                <div className="accordion-button bg-dark collapsed" data-bs-toggle="collapse" data-bs-target="#contactDetails" aria-expanded="false" aria-controls="contactDetails">
                                    Contact Details
                                </div>
                            </h2>
                            <div className="accordion-collapse collapse" id="contactDetails" aria-labelledby="contactHeader" data-bs-parent="#employeeAccordion">
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Mobile
                                            <div className="fw-bold">{employee?.mobileNumber}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Email
                                            <div className="fw-bold">{(employee?.email && employee?.email?.length > 0) ? employee.email : "--"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Permanent Address
                                            <div className="fw-bold">{(employee?.permanentAddress && employee?.permanentAddress?.length > 0) ? employee.permanentAddress : "--"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Current Address
                                            <div className="fw-bold">{(employee?.currentAddress && employee?.currentAddress?.length > 0) ? employee.currentAddress : "--"}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="otherHeader">
                                <div className="accordion-button collapsed bg-dark" data-bs-toggle="collapse" data-bs-target="#otherDetails" aria-expanded="false" aria-controls="otherDetails">
                                    Other Details
                                </div>
                            </h2>
                            <div className="accordion-collapse collapse" id="otherDetails" aria-labelledby="otherHeader" data-bs-parent="#employeeAccordion">
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Gender
                                            <div className="fw-bold">{(employee?.gender === "M") ? "MALE" : "FEMALE"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            DOB
                                            <div className="fw-bold">{(moment().diff(employee?.dob, 'years') > 10) ? moment(employee?.dob).format("DD MMMM YYYY") : "--"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Father Name
                                            <div className="fw-bold">{(employee?.fathersName && employee?.fathersName?.length > 0) ? employee.fathersName : "--"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Mother Name
                                            <div className="fw-bold">{(employee?.mothersName && employee?.mothersName?.length > 0) ? employee.mothersName : "--"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Maraital Status
                                            <div className="fw-bold">{(employee?.maritalStatus && employee?.maritalStatus?.length > 0) ? employee.maritalStatus : "--"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Spouse Name
                                            <div className="fw-bold">{(employee?.spouseName && employee?.spouseName?.length > 0) ? employee.spouseName : "--"}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="employmentHeader">
                                <div className="accordion-button bg-dark collapsed" data-bs-toggle="collapse" data-bs-target="#employmentDetails" aria-expanded="false" aria-controls="employmentDetails">
                                    Employment Details
                                </div>
                            </h2>
                            <div className="accordion-collapse collapse" id="employmentDetails" aria-labelledby="employmentHeader" data-bs-parent="#employeeAccordion">
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Joining Date
                                            <div className="fw-bold">{(moment().diff(employee?.joiningDate, 'years') > 10) ? moment(employee?.joiningDate).format("DD MMMM YYYY") : "--"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            PAN Number
                                            <div className="fw-bold">{(employee?.panNumber && employee?.panNumber?.length > 0) ? employee.panNumber : "--"}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Aadhar Number
                                            <div className="fw-bold">{(employee?.aadhaarNumber && employee?.aadhaarNumber?.length > 0) ? employee.aadhaarNumber : "--"}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {(!employee?.isPFOptedOut) &&
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="pfHeader">
                                    <div className="accordion-button bg-dark collapsed" data-bs-toggle="collapse" data-bs-target="#pfDetails" aria-expanded="false" aria-controls="pfDetails">
                                        PF Details
                                    </div>
                                </h2>
                                <div className="accordion-collapse collapse" id="pfDetails" aria-labelledby="pfHeader" data-bs-parent="#employeeAccordion">
                                    <div className="accordion-body">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                PF Number
                                                <div className="fw-bold">{(employee?.epfAccountNumber && employee?.epfAccountNumber?.length > 0) ? employee.epfAccountNumber : "--"}</div>
                                            </li>
                                            <li className="list-group-item">
                                                UAN Number
                                                <div className="fw-bold">{(employee?.uan && employee?.uan?.length > 0) ? employee.uan : "--"}</div>
                                            </li>
                                            <li className="list-group-item">
                                                Voluntery Contribution
                                                <div className="fw-bold">{employee?.pfVolunteryContribution}/-</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {(employee?.esiAccountNumber && employee?.esiAccountNumber?.length > 0) &&
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="esiHeader">
                                    <div className="accordion-button bg-dark collapsed" data-bs-toggle="collapse" data-bs-target="#esiDetails" aria-expanded="false" aria-controls="esiDetails">
                                        ESI Details
                                    </div>
                                </h2>
                                <div className="accordion-collapse collapse" id="esiDetails" aria-labelledby="esiHeader" data-bs-parent="#employeeAccordion">
                                    <div className="accordion-body">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                ESIC Number
                                                <div className="fw-bold">{(employee?.esiAccountNumber && employee?.esiAccountNumber?.length > 0) ? employee.esiAccountNumber : "--"}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="bankHeader">
                                <div className="accordion-button bg-dark collapsed" data-bs-toggle="collapse" data-bs-target="#bankDetails" aria-expanded="false" aria-controls="bankDetails">
                                    Bank Details
                                </div>
                            </h2>
                            <div className="accordion-collapse collapse" id="bankDetails" aria-labelledby="bankHeader" data-bs-parent="#employeeAccordion">
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Payment Mode
                                            <div className="fw-bold">{(employee?.defaultPaymentType && employee?.defaultPaymentType?.length > 0) ? employee.defaultPaymentType : "--"}</div>
                                        </li>
                                        {employee?.defaultPaymentType === "NetBanking" &&
                                            <>
                                                <li className="list-group-item">
                                                    Bank Name
                                                    <div className="fw-bold">{(employee?.bankName && employee?.bankName?.length > 0) ? employee.bankName : "--"}</div>
                                                </li>
                                                <li className="list-group-item">
                                                    IFSC Code
                                                    <div className="fw-bold">{(employee?.ifscCode && employee?.ifscCode?.length > 0) ? employee.ifscCode : "--"}</div>
                                                </li>
                                                <li className="list-group-item">
                                                    Account Number
                                                    <div className="fw-bold">{(employee?.bankAccountNumber && employee?.bankAccountNumber?.length > 0) ? employee.bankAccountNumber : "--"}</div>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <button className='btn btn-dark' onClick={() => navigate(-1)}>Back</button>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}