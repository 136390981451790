import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { AppSettings, BusinessSummaryModel, Employee, Transaction } from 'service';
import ArthaGateway from 'service/ServerGateway';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import attendance from '../../assets/images/attendance.png'
import payslip from '../../assets/images/payslip.png'
import profile from '../../assets/images/profile.png'
import leaves from '../../assets/images/leave.png'
import SettingsService from 'service/SettingsService';
import { ActionCreators } from 'store/actionCreators';

export const Dashboard = () => {
    const userData = useSelector((state: RootState) => state.appdata);
    const [user, setUser] = useState<any>();
    const [company, setCompany] = useState<any>();
    const [settings, setSettings] = useState<AppSettings>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (userData.profile) {
            setUser(userData.profile.profile.user);
            setCompany(userData.company);
            setIsLoading(false);

            // Get the server app settings
            SettingsService.get()
                .then((data) => {
                    setSettings(data);
                });
        }
    }, [userData]);

    const changeCompany = () => {
        dispatch(ActionCreators.changeCompany());
    }

    return (
        <>
            <div className="page-dashboard">
                <div className="container">
                    <div className='row'>
                        <div className='page-header card' style={{ paddingBottom: "10px" }}>
                            Welcome,
                            <h2>{user?.name}</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='page-header card' style={{ paddingBottom: "20px" }}>
                            <b>{company?.name}</b>
                            {userData.profile.profile.companies.length > 1 &&
                                <div className="btn btn-sm btn-secondary" style={{ marginTop: "10px" }} onClick={changeCompany}>
                                    Change Company
                                </div>
                            }
                        </div>
                    </div>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <div className='row'>
                            <div className="col-lg-3 col-md-6 col-6 mb-4">
                                <NavLink to="/attendance">
                                    <div className="card">
                                        <img className="card-img-top" src={attendance} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <h5 className="card-title">Attendance</h5>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-4">
                                <NavLink to="/payslip">
                                    <div className="card">
                                        <img className="card-img-top" src={payslip} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <h5 className="card-title">Payslips</h5>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-3 col-md-6 col-6 mb-4">
                                <NavLink to="/employees">
                                    <div className="card">
                                        <img className="card-img-top" src={profile} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <h5 className="card-title">Employees</h5>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};