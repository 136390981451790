import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {reducer as toastrReducer} from 'react-redux-toastr'

import appdata from './reducers';

export interface RootState {
  appdata: any;
}

const rootReducer = combineReducers({
  appdata: appdata,
  toastr: toastrReducer
});

const configureStore = () => {
  return createStore(
    rootReducer,
    compose(applyMiddleware(thunk))
  );
};

export default configureStore;