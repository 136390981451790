import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'store/actionCreators';
import { RootState } from 'store/store';

export const SelectCompany = () => {
    const user = useSelector((state: RootState) => state.appdata.profile.profile);
    const [companies, setCompanies] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            setIsLoading(false);
            setCompanies(user.companies);
            if (user.companies.length == 1) {
                selectCompany(user.companies[0]);
            }
        }
    }, [user]);

    const selectCompany = (company) => {
        dispatch(ActionCreators.selectCompany(company));
    }

    return (
        <div className="page-employees">
            <div className='container'>
                <div className='row'>
                    <h2>Select Company</h2>
                </div>
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <ul className="list-group list-group-flush">
                        {companies?.map((company) => (
                            <li className="list-group-item">
                                {company.name}<br />
                                <div className='btn btn-sm btn-primary' style={{ float: 'right' }} onClick={() => selectCompany(company)}>Select</div>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
    );
}