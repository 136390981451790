import { InstallPWA, Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SalesPerson } from 'service';
import ServerGateway from 'service/ServerGateway';
import { ActionCreators } from 'store/actionCreators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export const Login = () => {
    const [emailId, setemailId] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [salesPersons, setSalesPersons] = useState<SalesPerson[]>([]);
    const dispatch = useDispatch();

    {/* useEffect(() => {
        ServerGateway
        .getSalesperson()
        .then((data) => {
            setSalesPersons(data);
        })
        .catch(error => {
            console.log(error);
        });
      }, []); */}

    const validateLogin = () => {
        console.log("Validating the login");
        setIsValidating(true);
        ServerGateway
            .validateLogin(emailId, password)
            .then((data) => {
                const user = {
                    userName: data.user.name,
                    name: data.user.name,
                    profile: data
                };
                localStorage.removeItem("companyCode");
                localStorage.setItem("login_time", moment().format());
                dispatch(ActionCreators.login(user));
                setIsValidating(false);
            })
            .catch(error => {
                console.log(error);
                setShowAlert(true);
                setemailId("");
                setPassword("");
                setTimeout(() => setShowAlert(false), 10000);
                setIsValidating(false);
            });

        {/*let data = salesPersons.find((salesPerson) => (salesPerson.mobileNumber == emailId));
        if(data) {
            const user = {
                userName: data.name,
                name: data.name,
                salesPersonId: data.id
            };
            console.log("Dispatching login");
            dispatch(ActionCreators.login(user));
        }
        else {            
            setShowAlert(true);
            setemailId("");
            setPassword("");
            setTimeout(() => setShowAlert(false), 10000);
        }*/}
    };

    const clearCache = () => {
        caches.keys().then(function (names) {
            for (let name of names)
                caches.delete(name);
        });
        setTimeout(() => window.location.reload(), 5000);
    }

    return (
        <form>
            <InstallPWA />
            <div className='login-form'>
                <div className='container'>

                    {showAlert &&
                        <div className="alert alert-danger" role="alert" style={{ marginBottom: 20 }}>
                            The email id you entered does not match with any of the records.
                        </div>
                    }
                    <div className="form-outline mb-4">
                        <label className="form-label">Email Id</label>
                        <input type="text" className="form-control" value={emailId} onChange={(e) => setemailId(e.target.value)} />
                    </div>
                    <div className="form-outline mb-4">
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="form-outline mb-4" style={{ width: "100%" }}>
                        <div className='btn btn-dark w-100' onClick={validateLogin}>
                            {isValidating &&
                                <div className="spinner-border text-light" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                            {!isValidating &&
                                <>
                                    <FontAwesomeIcon icon={faRightToBracket} style={{ marginRight: 10 }} />
                                    Login
                                </>
                            }
                        </div>
                    </div>
                    <div className='version-number'>
                        <div className="btn btn-sm bg-dark" style={{ marginLeft: 20, marginBottom: 10 }} onClick={clearCache}>Update to latest version</div>
                        <span style={{ marginRight: 20, float: "right" }} >v 1.0.0.2</span>
                    </div>
                </div>
            </div>
        </form>
    );
};