import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toastr } from 'react-redux-toastr'
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faDownload, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export const Leave = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [employee, setEmployee] = useState<Employee>();
    const [durationType, setDurationType] = useState<number>(-1);
    const [from, setFrom] = useState<any>(moment().startOf('year').format("YYYY-MM-DD"));
    const [to, setTo] = useState<any>(moment().endOf('year').format("YYYY-MM-DD"));
    const [leaves, setLeaves] = useState<any[]>([]);
    const [leaveBalances, setLeaveBalances] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setEmployee(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        loadLeaveBalancess();
        loadLeaves();
    }, [employee]);

    const loadLeaves = () => {
        setIsLoading(true);
        ServerGateway
            .getLeaves(employee?.id, durationType, from, to)
            .then((data) => {
                setLeaves(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const loadLeaveBalancess = () => {
        setIsLoading(true);
        ServerGateway
            .getLeaveBalance(employee?.id)
            .then((data) => {
                setLeaveBalances(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const getClasNameByStatus = (status) => {
        if (status === "Requested") {
            return "badge bg-warning"
        }
        else if (status === "Approved") {
            return "badge bg-success"
        }
        else if (status === "Rejected") {
            return "badge bg-danger"
        }
    }


    return (
        <div className="page-payslip">
            <div className='container'>
                <div className='row'>
                    <NavLink to="/leave/request" className='btn bg-primary'>
                        <FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: 10 }} />
                        Request Leave
                    </NavLink>
                </div>
                <div className="row">
                    <div className="form-group">
                        <label htmlFor="from">From</label>
                        <input type="date" className="form-control" id="from" value={from} onChange={(e) => setFrom(e.target.value)}></input>
                    </div>
                    <div className="form-group">
                        <label htmlFor="to">To</label>
                        <input type="date" className="form-control" id="to" value={to} onChange={(e) => setTo(e.target.value)}></input>
                    </div>
                    <div className="btn bg-secondary" onClick={loadLeaves}>
                        Reload Data
                    </div>
                </div>
                <div className='row' style={{ marginTop: "20px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <>
                            {(leaves.length == 0) &&
                                <div className="alert alert-secondary" role="alert">
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: 10 }} />
                                    No leave request available
                                </div>
                            }
                            {(leaveBalances.length > 0) &&
                                <table className="table table-striped">
                                    <tr>
                                        <th>Type</th>
                                        <th>Balance</th>
                                    </tr>
                                    {leaveBalances && leaveBalances.map((balance) => (
                                        <>
                                            {balance.balance > 0 &&
                                                <tr>
                                                    <td>{balance.leaveTypeName}</td>
                                                    <td>{balance.balance}</td>
                                                </tr>
                                            }
                                        </>
                                    ))}
                                </table>
                            }
                            {(leaves.length > 0) &&
                                <ul className="list-group list-group-flush">
                                    {leaves && leaves.map((request) => (
                                        <li className="list-group-item" style={{ marginBottom: "20px" }}>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item">
                                                    Leave Type
                                                    <div className="fw-bold">
                                                        {request.leaveType.name}
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    Duration
                                                    <div className="fw-bold">
                                                        {moment(request.from).format("YYYY-MM-DD")} - {moment(request.to).format("YYYY-MM-DD")}
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    Number Of Days
                                                    <div className="fw-bold">
                                                        {request.numberOfDays}
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    Reason
                                                    <div className="fw-bold">
                                                        {request.reason}
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    Status
                                                    <div className="fw-bold">
                                                        <div className={getClasNameByStatus(request.status)}>{request.status}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}