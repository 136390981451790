import { Loader } from '../Widgets';
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { AppSettings, Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';
import c3 from 'c3';
import 'c3/c3.css';

export const Attendance = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [groups, setGroups] = useState<[]>([]);
    const [groupId, setGroupId] = useState<number>(0);
    const [date, setDate] = useState<any>(moment().format("YYYY-MM-DD"));
    const [attendance, setAttendance] = useState<any[]>([]);
    const [summary, setSummary] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const chartRef = useRef(null);

    useEffect(() => {
        if (groups.length === 0) {
            ServerGateway.getEmployeeGroups()
                .then((data) => {
                    setGroups(data);
                });
        }
    }, []);

    useEffect(() => {
        if (date) {
            loadAttendance();
        }
    }, [date, groupId]);

    const loadAttendance = () => {
        setIsLoading(true);
        ServerGateway
            .getAttendance(date, groupId)
            .then((data) => {
                console.log(data);
                setAttendance(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (date && attendance) {
            let summary = {
                numOfEmployees: attendance.length,
                numOfPresent: attendance.filter(x => (x.status == "Present" || x.status == "HalfDay")).length,
                numOfAbsent: attendance.filter(x => (x.status == "Absent" || x.status == "NA" || x.status == 'LossOfPay')).length,
                numOfLeave: attendance.filter(x => x.status == "Leave").length,
                numOfLateLogins: attendance.filter(x => x.lateLoginTime > 0).length,
                numOfEarlyLogouts: attendance.filter(x => x.earlyLogoutTime > 0).length
            }            
            console.log("Summary = "+ JSON.stringify(summary));
            setSummary(summary);
        }
    }, [attendance]);

    useEffect(() => {
        if (summary) {
            drawAttendanceChart();
        }
    }, [summary]);

    const drawAttendanceChart = () => {
        console.log("Drawing c3 Graph");
        c3.generate({
            bindto: '#attendance-chart',
            legend: { show: false },
            data: {
                columns: [
                    ['Present', summary.numOfPresent],
                    ['Absent', summary.numOfAbsent],
                    ['Leave', summary.numOfLeave],
                ],
                colors: {
                    Present: '#69c050',
                    Absent: '#f44336',
                    Leave: '#ffca28',
                },
                type: 'pie'
            }
        });
    }

    const showAttendanceDetails = (status) => {
    }

    const handleDateChange = (e) => {
        setDate(e.target.value);
    }

    const getClassNameBasedOnStatus = (status) => {
        if (status === "Present") {
            return "badge bg-success"
        }
        else if (status === "Absent" || status === "LOP") {
            return "badge bg-danger"
        }
        else if (status === "Leave") {
            return "badge bg-warning"
        }
        else if (status === "WeeklyOff") {
            return "badge bg-primary"
        }
        return "badge bg-secondary";
    }

    return (
        <div className="page-attendance">
            <div className='container'>
                <div className='row'>
                    <h2>Attendance</h2>
                </div>
                <div className='row'>
                    <input type="date" className="form-control" value={date} onChange={handleDateChange} style={{ textAlign: "center" }}></input>
                </div>
                <div className='row'>
                    <select className="form-control" value={groupId} onChange={(e) => setGroupId(parseInt(e.target.value))}>
                        <option value="0">All</option>
                        {groups.map((group: any) => {
                            return <option key={group.id} value={group.id}>{group.name}</option>
                        })}
                    </select>
                </div>
                <div className='row' style={{ marginTop: "10px" }}>
                    <div className='col-md-12'>
                        <div ref={chartRef} id="attendance-chart"></div>
                    </div>
                </div>
                <div className='row' style={{ marginTop: "10px" }}>
                    <table className="table table-bordered table-hover" style={{ textAlign: "center" }}>
                        <tr>
                            <td style={{ width: "25%" }}>
                                Total<br/>
                                <b>{summary?.numOfEmployees}</b>
                            </td>
                            <td style={{ width: "25%" }}>
                                Present<br/>
                                <b>{summary?.numOfPresent}</b>
                            </td>
                            <td style={{ width: "25%" }}>
                                Absent<br/>
                                <b>{summary?.numOfAbsent}</b>
                            </td>
                            <td>
                                Leave<br/>
                                <b>{summary?.numOfLeave}</b>
                            </td>
                        </tr><tr>
                            <td colSpan={2}>
                                Late Login<br/>
                                <b>{summary?.numOfLateLogins}</b>
                            </td>
                            <td  colSpan={2}>
                                Early Logout<br/>
                                <b>{summary?.numOfEarlyLogouts}</b>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div >
    );
}