import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export const Payslip = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [month, setMonth] = useState<any>();
    const [netPayable, setNetPayable] = useState<number>(0);
    const [grossSalary, setGrossSalary] = useState<number>(0);
    const [totalDeduction, setTotalDeduction] = useState<number>(0);
    const [payslips, setPayslips] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {

    }, [user]);

    useEffect(() => {
        if (month) {
            setIsLoading(true);
            var month_data = moment(month + "-01")
            ServerGateway
                .getPayslips(moment(month_data).year(), (moment(month_data).month() + 1))
                .then((data) => {
                    data.forEach((payslip) => { payslip.isExpanded = false; });
                    setPayslips(data);
                    setGrossSalary(data.reduce((a, b) => a + b.totalEarnings, 0));
                    setTotalDeduction(data.reduce((a, b) => a + b.totalDeductions, 0));
                    setNetPayable(data.reduce((a, b) => a + b.netPayable, 0));
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [month]);

    useEffect(() => {
        setMonth(moment().subtract(1, 'months').startOf('month').format('YYYY-MM'));
    }, []);

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    }

    const toggleExapnd = (target) => {
        payslips?.map((payslip) => {
            if (payslip.id === target.id) {
                payslip.isExpanded = !payslip.isExpanded;
            }
            else {
                payslip.isExpanded = false;
            }
        });
        setPayslips([...payslips]);
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <div className="page-payslip">
            <div className='container'>
                <div className='row'>
                    <input type="month" className="form-control" value={month} onChange={handleMonthChange} style={{ textAlign: "center" }}></input>
                </div>
                <div className='row' style={{ marginTop: "20px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <>
                            {payslips.length == 0 &&
                                <div>Payslip information not available</div>
                            }
                            {payslips.length > 0 &&
                                <>
                                    <table style={{ textAlign: "center" }} className="table table-bordered table-hover">
                                        <tr>
                                            <td>
                                                # of payslips
                                                <div className="fw-bold">{payslips?.length}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Gross Salary
                                                <div className="fw-bold">{currencyFormat(grossSalary)}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Deduction
                                                <div className="fw-bold">{currencyFormat(totalDeduction)}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Net Payable
                                                <div className="fw-bold">{currencyFormat(netPayable)}</div>
                                            </td>
                                        </tr>
                                    </table>
                                    <div style={{ marginTop: "10px" }}>
                                        <div className="accordion" id="employeeAccordion">
                                            {payslips?.map((payslip) => (
                                                <>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={payslip.id.toString()} onClick={() => toggleExapnd(payslip)}>
                                                            <div className={payslip.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                                                {payslip.employee.name}
                                                            </div>
                                                        </h2>
                                                        <div id={"d" + payslip.id.toString()} className={payslip.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"d" + payslip.id.toString()}>
                                                            <div className="accordion-body">
                                                                <ul className="list-group list-group-flush">
                                                                    <li className="list-group-item">
                                                                        Employee ID
                                                                        <div className="fw-bold">{payslip.employee.id}</div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        Name
                                                                        <div className="fw-bold">{payslip.employee.name}</div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        Group
                                                                        <div className="fw-bold">{payslip.employee.groupName}</div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        Designation
                                                                        <div className="fw-bold">{payslip.employee.designationName}</div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        Total Earnings
                                                                        <div className="fw-bold">{currencyFormat(payslip.totalEarnings)}</div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        Deduction
                                                                        <div className="fw-bold">{currencyFormat(payslip.totalDeductions)}</div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        Net Payable
                                                                        <div className="fw-bold">{currencyFormat(payslip.netPayable)}</div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}