import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFileInvoice, faHome, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import { ActionCreators } from 'store/actionCreators';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { AppSettings } from 'service';
import SettingsService from 'service/SettingsService';


export const BottomBar = () => {
    const dispatch = useDispatch();

    const [settings, setSettings] = useState<AppSettings>();
    useEffect(() => {
            // Get the server app settings
            SettingsService.get()
                .then((data) => {
                    setSettings(data);
                });
    }, []);

    const confirmLogout = () => {
        swal({
            title: "Confirm logout",
            text: "Are you sure you want to logout?",
            icon: "warning",
            dangerMode: true,
            buttons: {
                default: { text: 'Cancel' },
                cancel: { text: 'Cancel' },
                confirm: { text: 'Logout' }
            }
          })
          .then((confirmLogout) => {
            if (confirmLogout === true) {
                dispatch(ActionCreators.logout());
            }
          });
    }

    return (
        <>
            <nav className="navbar fixed-bottom navbar-light bg-light app-bottom-bar">                
                <li className="nav-item" title="Home">
                    <NavLink to="/">
                        <FontAwesomeIcon icon={faHome} />
                    </NavLink>
                </li>
                <li className="nav-item"  title="Attendance">
                    <NavLink to="/attendance">
                        <FontAwesomeIcon icon={faCalendar} />
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/employees" title="Employees">
                        <FontAwesomeIcon icon={faUser} />
                    </NavLink>
                </li> 
                <li className="nav-item"  title="Payslips">
                    <NavLink to="/payslip">
                        <FontAwesomeIcon icon={faFileInvoice} />
                    </NavLink>
                </li>    
                <li className="nav-item" title="Logout">
                    <a onClick={confirmLogout} href="#">
                        <FontAwesomeIcon icon={faRightFromBracket} />
                    </a>
                </li>   
            </nav>
        </>
    )
};
