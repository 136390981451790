import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { NavLink } from 'react-router-dom';

export const EmployeeList = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [groups, setGroups] = useState<[]>([]);
    const [groupId, setGroupId] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (groups.length === 0) {
            ServerGateway.getEmployeeGroups()
                .then((data) => {
                    setGroups(data);
                });
        }
    }, []);

    useEffect(() => {
        if (pageNumber != 1) {
            setPageNumber(1);
        }
        else {
            loadEmployees();
        }
    }, [groups, groupId, name, pageSize]);

    useEffect(() => {
        loadEmployees();
    }, [pageNumber]);

    const loadEmployees = () => {
        setIsLoading(true);
        ServerGateway
            .getEmployees(name, groupId, pageNumber, pageSize)
            .then((data) => {
                data.entries.forEach((employee: Employee) => { employee.isExpanded = false; });
                console.log(data.entries);
                if (pageNumber == 1) {
                    setEmployees(data.entries);
                }
                else {
                    setEmployees([...employees, ...data.entries]);
                }
                setTotalPages(data.totalPages);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const toggleExapnd = (target) => {
        employees?.map((employee) => {
            if (employee.id === target.id) {
                employee.isExpanded = !employee.isExpanded;
            }
            else {
                employee.isExpanded = false;
            }
        });
        setEmployees([...employees]);
    }

    return (
        <div className="page-employees">
            <div className='container'>
                <div className='row'>
                    <h2>Employees</h2>
                </div>
                <div className='row'>
                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div className='row'>
                    <select className="form-control" value={groupId} onChange={(e) => setGroupId(parseInt(e.target.value))}>
                        <option value="0">All</option>
                        {groups.map((group: any) => {
                            return <option key={group.id} value={group.id}>{group.name}</option>
                        })}
                    </select>
                </div>
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <div className='row' style={{ marginTop: "10px" }}>
                        <div className="accordion" id="employeeAccordion" style={{ padding: 0 }}>
                            {employees?.map((employee: Employee) => (
                                <>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={employee.id.toString()} onClick={() => toggleExapnd(employee)}>
                                            <div className={employee.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                                {employee.name}
                                            </div>
                                        </h2>
                                        <div id={"d" + employee.id.toString()} className={employee.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"d" + employee.id.toString()}>
                                            <div className="accordion-body">
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">
                                                        Employee ID
                                                        <div className="fw-bold">{employee.id}</div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        Name
                                                        <div className="fw-bold">{employee.name}</div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        Group
                                                        <div className="fw-bold">{employee.groupName}</div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        Designation
                                                        <div className="fw-bold">{employee.designationName}</div>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`/employee/${employee.id}`} className="btn btn-primary w-100">View Details</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                            {employees.length === 0 &&
                                <div className="row">
                                    <div className="alert alert-info">No employees found</div>
                                </div>
                            }
                            {pageNumber < totalPages &&
                                <div className="row" style={{ margin: "0.25rem" }}>
                                    <button className="btn btn-primary" onClick={() => setPageNumber(pageNumber + 1)}>Load More</button>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}